import React from 'react';
import styled from "@emotion/styled"
import { themeGet } from '@styled-system/theme-get'

import { Section } from '../base';
import { ColorText } from '../base/typo';

type ParagraphProps = {
    children: any,
    color?: ColorText,

}
export const SimpleParagraph = ({ children, color = 'lightGray' }: ParagraphProps) => <Container color={color}>
        {children}
</Container>

export const SectionParagraph = (props: ParagraphProps) => <Section>
    <SimpleParagraph {...props} />
</Section>

const Container = styled.div<{color:ColorText}>`
${({color='lightGray', ...props}) => `color: ${themeGet(`colors.${color}`)(props)}`};
`
