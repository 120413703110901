import React, { useState } from 'react';

import { Header } from '../header/header';
import { BottomMenu } from '../bottomMenu/bottomMenu';
import { BG_TYPES } from '../svg/Backgrounds';
import { Footer } from '../footer/footer';

import useWindowSize, { isMobile } from '../../hooks/useWindowSize';

type MenuWrapperProps = {
    children: any,
    pageName?: string,
    footer?: boolean,
    bgType?: BG_TYPES
}
export const MenuWrapper = ({ children, pageName, bgType=BG_TYPES.PRIMARY, footer = true }: MenuWrapperProps) =>{
    const {type} = useWindowSize()
    const [menuOpen, setMenuOpen] = useState(false)
    const toggleMenu = () => {setMenuOpen(!menuOpen)}
    return  <>
                <Header
                    bgType={bgType}
                    pageName={pageName}
                    toggleMenu={toggleMenu}
                    menuOpen={menuOpen}
                />
                {children}
                {footer && <Footer />}
                {isMobile(type) && <BottomMenu bgType={BG_TYPES.WHITE} toggleMenu={toggleMenu} menuOpen={menuOpen}/>}
            </>
}
