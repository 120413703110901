
import styled from "@emotion/styled";
import tw from "tailwind.macro";

export const FullScreenContent = styled.div<{layout:string, alignVertical?:string}>`
    display: grid;
    grid-template-columns: ${({layout}) => layout};
    grid-gap: 0;
    justify-items: center;
    align-items: ${({alignVertical = 'center'}) => alignVertical};
    z-index: 7;
    ${tw`
        absolute
        top-0
        w-full
        min-h-screen
        h-screen
        px-5
    `}
`

export const MobileFullScreenContent = styled.div`
    z-index: 7;
    ${tw`
    absolute
    w-full
    min-h-screen
    h-screen
    px-5
    pt-10
    `}
`

export const ContentLeft = styled.div<{marginVertical?:number}>`
    grid-column: 2;
    margin-top: ${({marginVertical = 0}) => marginVertical}px;
    margin-left: -80px;
    margin-right: -80px;
`

export const ContentRigth = styled.div<{position?:number, marginVertical?:number}>`
    grid-column: ${({position = 4}) => position};
    margin-top: ${({marginVertical = 0}) => marginVertical}px;
`