import React, { forwardRef } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import { themeGet } from "@styled-system/theme-get"

import { Text } from "../base"

import { SvgArrow } from "../../assets/svg"

type ButtonProps = {
  text: any
  link: any
}

type ActionButtonProps = {
  text: any
  onClick: Function
}

type TagButtonProps = {
  text: any
  onClick: Function
  active?: boolean
  thin?: boolean
}

export const Button = ({ text, link }: ButtonProps) => (
  <ButtonLink to={link}>{text}</ButtonLink>
)

export const SecondaryButton = ({ text, link }: ButtonProps) => (
  <SecondaryButtonContainer to={link}>{text}</SecondaryButtonContainer>
)

export const ActionButton = forwardRef(
  ({ text, onClick, ...otherProps }: ActionButtonProps & any, ref: any) => (
    <ButtonContainer
      ref={ref}
      onClick={() => {
        if (onClick) onClick()
      }}
      {...otherProps}
    >
      {text}
    </ButtonContainer>
  )
)

export const TagButton = ({
  text,
  onClick,
  active = false,
  thin,
}: TagButtonProps) => (
  <TagButtonContainer onClick={() => onClick()} active={active}>
    {text}
  </TagButtonContainer>
)

export const BackButton = ({ text, link }) => (
  <div>
    <Link to={link}>
      <BackButtonContainer>
        <ArrowContainer>
          <SvgArrow />
        </ArrowContainer>
        <Text size="sm">{text}</Text>
      </BackButtonContainer>
    </Link>
  </div>
)

const getStyledButton = styledDiv => styledDiv`
    padding: 0.9em 0;
    ${tw`
        px-10
        text-md
        font-bold
        rounded-full
        cursor-pointer
    `}
    :hover {
        ${tw`shadow-lg`}
    }
    :focus {
        ${tw`shadow-lg`}
    }
    :active {
        ${tw`shadow-lg`}
    }
`
const ButtonStyleLink = getStyledButton(styled(Link))
const ButtonStyle = getStyledButton(styled.span)

const ButtonContainer = styled(ButtonStyle)`
  background-image: ${({ ...props }) =>
    `linear-gradient(to bottom left, ${themeGet(`colors.primary21`)(
      props
    )}, ${themeGet(`colors.primary22`)(props)})`};
  ${tw`
        text-white
    `}
`

const ButtonLink = styled(ButtonStyleLink)`
  background-image: ${({ ...props }) =>
    `linear-gradient(to bottom left, ${themeGet(`colors.primary21`)(
      props
    )}, ${themeGet(`colors.primary22`)(props)})`};
  ${tw`
        text-white
    `}
`

const SecondaryButtonContainer = styled(ButtonStyleLink)`
  ${tw`
        text-primary21
        bg-white
    `}
`

const BackButtonContainer = styled.div`
  ${tw`
        flex
        items-center
    `}
`

const ArrowContainer = styled.div`
  background-image: ${({ ...props }) =>
    `linear-gradient(to bottom left, ${themeGet(`colors.primary21`)(
      props
    )}, ${themeGet(`colors.primary22`)(props)})`};
  ${tw`
        w-10
        h-10
        rounded-full
        flex
        justify-center
        items-center
        text-white
        mr-5
    `}
  :hover {
    ${tw`shadow-lg`}
  }
`

const TagButtonContainer = styled(ButtonContainer)<{ active?: boolean }>`
  ${tw`
        px-4
        py-1
        bg-primary21
    `}
  background-image: none;
  ${({ active }) => active && tw`shadow-lg`}
  ${({ active = false, ...props }) =>
    !active &&
    `
        background-color: #fff;
        color: ${themeGet(`colors.primary21`)(props)};
        border: 1px solid ${themeGet(`colors.primary21`)(props)};
    `};
`
