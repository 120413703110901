import styled from "@emotion/styled"
import tw from "tailwind.macro"

export const Row = styled.div`
${tw`
flex
w-full
`}
`

export const Col = styled.div`
${tw`
flex
flex-col
content-center
`}
`

export const Section = styled.div`
${tw`
my-5
md:my-8
bg-transparent
`}
`