import ReactMarkdown from "react-markdown"

export const CustomReactMarkdown = ({ source, ...props }) => {
  const sourceHTTPS = source?.replace(
    /http:\/\/142.93.141.215:1337/g,
    "https://images.weserv.nl/?url=http://142.93.141.215:1337"
  )
  return (
    <ReactMarkdown
      {...props}
      source={sourceHTTPS}
      renderers={{
        link: props => (
          <a href={props.href} target="_blank">
            {props.children}
          </a>
        ),
      }}
    />
  )
}
