import React from 'react';
import tw from "tailwind.macro";

import { Text, Section } from '../base';
import { ColorText } from '../base/typo';

type TitleProps = {
    children:any,
    section?:boolean,
    bold?:boolean,
    color?:ColorText
}

export const Title = ({children, section = true, bold = false, color = 'black'}:TitleProps) => {
    const weight = bold ? 'bold' : 'normal';
    const Content = <Text css={tw`text-3xl leading-tight bg-transparent`} weight={weight} color={color}>{children}</Text>
    return (
        <>
            {section
            ? <Section>{Content}</Section>
            : Content
            }
        </>
    )
}


export const Subtitle = ({children}) => <div className='my-3'>
<Text css={tw`text-black font-bold`} >{children}</Text>
</div>