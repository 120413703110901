import React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import { useStaticQuery, graphql } from "gatsby"
import { themeGet } from "@styled-system/theme-get"
import { Link, useIntl } from "gatsby-plugin-intl"

import useWindowSize, { getSize } from "../../hooks/useWindowSize"

import { Row, Text } from "../base"
import { SocialLink } from "../../content/team/memberItem"
import {
  SvgInstagramNegativeIcon,
  SvgLinkedinNegativeIcon,
} from "../../assets/svg"

export const Footer = () => {
  const { locale } = useIntl()
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allStrapiMenu {
        nodes {
          homeLinkText
          servicesLinkText
          storiesLinkText
          musesLinkText
          partnersLinkText
          contactLinkText
          locale
        }
      }
      allStrapiFooter {
        nodes {
          contactAddress
          contactEmail
          copyright
          locale
          sectionInfoName
          sectionLinksName
          supportMessage
          instagramLink
          linkedinLink
        }
      }
    }
  `)
  // termAndConditionText
  // privacyPolicyText
  // sectionLegalName

  const langDataMenu = data.allStrapiMenu?.nodes?.filter(
    node => node.locale === locale
  )
  const langDataFooter = data.allStrapiFooter?.nodes?.filter(
    node => node.locale === locale
  )
  const {
    homeLinkText,
    servicesLinkText,
    storiesLinkText,
    musesLinkText,
    partnersLinkText,
    contactLinkText,
  } = langDataMenu?.[0] ?? {}
  const {
    contactAddress,
    contactEmail,
    copyright,
    sectionInfoName,
    // sectionLegalName,
    sectionLinksName,
    supportMessage,
    //termAndConditionText,
    //privacyPolicyText,
    linkedinLink,
    instagramLink,
  } = langDataFooter?.[0] ?? {}

  const { type } = useWindowSize()
  const layout = getSize(type, ["1fr", "1fr 2fr 2fr 1fr", "repeat(6, 1fr)"])
  return (
    <>
      <FooterContainer layout={layout}>
        <FirstRowElem position={getSize(type, [1, 2, 2])} className="pr-5 pb-5">
          <Text color="white" size="sm" weight="thin">
            {sectionInfoName}
          </Text>
          <div className="my-3" />
          <Text color="white" weight="bold">
            {contactEmail}
          </Text>
          <div className="my-3" />
          <Text color="white">{contactAddress}</Text>
        </FirstRowElem>
        <div className="pr-5 pb-5">
          <Text color="white" size="sm" weight="thin">
            {sectionLinksName}
          </Text>
          <div className="my-3" />
          <Menu>
            <div>
              <CustomLink to="/">{homeLinkText}</CustomLink>
              <CustomLink to="/services">{servicesLinkText}</CustomLink>
              <CustomLink to="/stories">{storiesLinkText}</CustomLink>
            </div>
            <div>
              <CustomLink to="/muses">{musesLinkText}</CustomLink>
              <CustomLink to="/partners">{partnersLinkText}</CustomLink>
              <CustomLink to="/contact">{contactLinkText}</CustomLink>
            </div>
          </Menu>
        </div>
        <FirstRowElem position={getSize(type, [1, 2, 4])} className="pr-5 pb-5">
          <div className="my-8" />
          <Text color="white">{supportMessage}</Text>
        </FirstRowElem>
        <div className="pr-5 pb-5">
          {/* <Text color='white' size='sm' weight='thin'>{sectionLegalName}</Text> */}
          {/* <div className='my-3' /> */}
          {/* <CustomLink to='/'>{termAndConditionText}</CustomLink> */}
          {/* <CustomLink to='/'>{privacyPolicyText}</CustomLink> */}
          <div className="my-8" />
          <Text color="white" size="sm">
            {copyright}
            <a
              target="_blank"
              href="https://www.appik-studio.ch"
              // rel='noopener noreferrer'
            >
              , made by Appik Studio
            </a>
          </Text>
          <Row className="my-4">
            {linkedinLink && (
              <SocialLink negative={true} href={linkedinLink} target="_blank">
                <SvgLinkedinNegativeIcon className="w-5 h-5" />
              </SocialLink>
            )}
            {instagramLink && (
              <SocialLink negative={true} href={instagramLink} target="_blank">
                <SvgInstagramNegativeIcon className="w-5 h-5" />
              </SocialLink>
            )}
          </Row>
        </div>
      </FooterContainer>
    </>
  )
}

const FooterContainer = styled.div<{ layout: string }>`
  background-image: ${({ ...props }) =>
    `linear-gradient(to bottom left, ${themeGet(`colors.primary21`)(
      props
    )}, ${themeGet(`colors.primary22`)(props)})`};
  display: grid;
  grid-template-columns: ${({ layout }) => layout};
  grid-gap: 0px;
  ${tw`
        w-full
        p-8
        pb-20
    `}
`

const FirstRowElem = styled.div<{ position: number }>`
  grid-column: ${({ position }) => position};
`

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
`

const CustomLink = styled(Link)`
  ${tw`
        text-white
        block
    `}
`
