import React from 'react';

import useWindowSize, { getSize } from '../../hooks/useWindowSize';
import styled from '@emotion/styled';

const BackgroundCircle = () => {
  const {type, width, height} = useWindowSize()
  const r = getSize(type,[width-20, width-80, width/2])
    return <Circle d={r*2} width={width} height={height}/>
}
export default BackgroundCircle;

const Circle = styled.div<{d:number, width:number, height:number}>`
  width: ${({d=100}) => d}px;
  height: ${({d=100}) => d}px;
  border-radius: 50%;
  top: ${({d=100, height}) => -(d-height)/2}px;
  left: ${({d=100}) => -d/2}px;
  position: absolute;
  background: radial-gradient(circle at 95%, #ff7079, #6a295f 50%, #6a295f 75%, #6a295f 75%);
  box-shadow: 0 0 30px 3px #fe9d97;
`