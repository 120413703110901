import styled  from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get'

export type SizeText = "xs" | "sm" | "base" | "lg" | '2xl' | '3xl' | '5xl';
export type WeigthText = "hairline" | "thin" | "light" | "normal" | "bold"
export type ColorText = "gray" | "black" | "lightGray" | 'primary22' | 'white'

export const Text = styled.p<{size?:SizeText, weight?:WeigthText, color?:ColorText}>`
${({size='base', ...props}) => `font-size: ${themeGet(`fontSize.${size}`)(props)}`};
${({weight='normal', ...props}) => `font-weight: ${themeGet(`fontWeight.${weight}`)(props)}`};
${({color='lightGray', ...props}) => `color: ${themeGet(`colors.${color}`)(props)}`};
`

