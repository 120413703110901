import React from 'react';


type CircleProps = {
    r: number | string,
    cx:number | string,
    cy:number | string,
    height?: number | string,
    width?: number | string,
    fill?: string,
    stroke?:string,
    otherProps?:any,
    className?:any
}
const Circle = ({r=100,fill,stroke,cx,cy,height="100%",width="100%",className, ...otherProps}:CircleProps) => {
    return <svg className={className} {...{height,width}}>
<circle {...{fill,stroke,r,cx,cy,...otherProps}} />
</svg>}
export default Circle;
