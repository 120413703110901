import React from 'react';
import { useTheme } from 'emotion-theming';

import useWindowSize, { getSize }  from '../../hooks/useWindowSize';

import Circle from './circle';

export enum BG_TYPES {
    HOME_3,
    HOME_4,
    HOME_5,
    PRIMARY,
    WHITE,
    NONE
}

export const getHeaderStyle = (bgType, theme) => {
    if(bgType === BG_TYPES.NONE) return `position:sticky; background-image: none;`
    if(bgType === BG_TYPES.PRIMARY) return `position:sticky; background-image: linear-gradient(to right, ${theme.colors.primary21}, ${theme.colors.primary22});`
    if(bgType === BG_TYPES.WHITE) return `position:sticky; background-color: white;`
    return `position: absolute; background-color:${theme.colors.transparent};`
}

export const SvgBackground = ({bgType = BG_TYPES.HOME_3}) => {
    const {type, width, height} = useWindowSize()
    const theme:any = useTheme()
    let fill = theme.colors.background

    let r,cyGap,cx1,cx2,cy1,cy2;

    r = (height/2);

    switch (bgType){
        case BG_TYPES.HOME_3: // vertical
            cyGap =  r * getSize(type,[3/4, 0, 0])
            cx1 = width/2;
            cx2 = width/2;
            cy1 = 0 - cyGap;
            cy2 = height + cyGap;
            break;
        case BG_TYPES.HOME_4: // only bottom
            r = width /2 + getSize(type,[width/2, 0, (height - width/2) ])
            cyGap = r * getSize(type,[1/5, 1/3, 0])
            cx2 = width/2;
            cy2 = height + cyGap
            // hide the first circle
            cx1 = 0;
            cy1= 10 * r;
            break;
        case BG_TYPES.HOME_5: // only top for mobile, full for others
            r = width  + r * getSize(type,[0, 10, 10])
            cyGap = r * getSize(type,[1/5, 0, 0])
            cx1 = width/2;
            cy1 = - cyGap;
            // hide the second circle
            cx2 = 0;
            cy2= 10 * r
            break;
    }

    return <svg {...{height,width}} className="overflow-visible" preserveAspectRatio="none" >
    <Circle  {...{r,fill, cx:cx1, cy:cy1}} />
    <Circle  {...{r,fill, cx:cx2, cy:cy2}}  />
</svg>
}

