import React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
import ReactMarkdown from "react-markdown"
import { themeGet } from "@styled-system/theme-get"
import { Link } from "gatsby"

import { Row } from "../../components/base"
import { SimpleParagraph } from "../../components/content/paragraph"
import { Text } from "../../components/base"
import { Title } from "../../components/content/title"

import {
  SvgInstagramIcon,
  SvgTwitterIcon,
  SvgWebsiteIcon,
  SvgFacebookIcon,
  SvgLinkedinIcon,
} from "../../assets/svg"
import { hexToRGBA } from "../../helpers/utils"
import { CustomReactMarkdown } from "../../components/customReactMarkdown"

type memberItemProps = {
  role: any
  name: any
  degree?: any
  image: any
  description: any
  webLink?: any
  twitterLink?: any
  linkedinLink?: any
  facebookLink?: any
  instagramLink?: any
  width?: number
}

export const MemberItem = ({
  role,
  name,
  degree,
  image,
  description,
  webLink,
  twitterLink,
  linkedinLink,
  facebookLink,
  instagramLink,
  width,
}: memberItemProps) => (
  <StoryContainer>
    <InfoContainer>
      <InfoTextContainer dim={width}>
        <Text size="xs">{degree}</Text>
        <Title section={false} bold={true}>
          {name}
        </Title>
        <Text>{role}</Text>
      </InfoTextContainer>
      <MemberImage src={image} dim={width} />
    </InfoContainer>
    <SimpleParagraph>
      <Text size="lg" weight="light">
        <CustomReactMarkdown source={description} />
      </Text>
    </SimpleParagraph>
    <Row className="py-6">
      {webLink && (
        <SocialLink href={webLink} target="_blank">
          <SvgWebsiteIcon className="w-5 h-5" />
        </SocialLink>
      )}
      {twitterLink && (
        <SocialLink href={twitterLink} target="_blank">
          <SvgTwitterIcon className="w-5 h-5" />
        </SocialLink>
      )}
      {linkedinLink && (
        <SocialLink href={linkedinLink} target="_blank">
          <SvgLinkedinIcon className="w-5 h-5" />
        </SocialLink>
      )}
      {facebookLink && (
        <SocialLink href={facebookLink} target="_blank">
          <SvgFacebookIcon className="w-5 h-5" />
        </SocialLink>
      )}
      {instagramLink && (
        <SocialLink href={instagramLink} target="_blank">
          <SvgInstagramIcon className="w-5 h-5" />
        </SocialLink>
      )}
    </Row>
  </StoryContainer>
)

const StoryContainer = styled.div`
  ${tw`
        py-8
    `}
`
const InfoTextContainer = styled.div<{ dim: number }>`
  width: ${({ dim }) => `${dim}px`};
  @media (min-width: 640px) {
    ${tw`
        pl-8
        `}
  }
`

const InfoContainer = styled(Row)`
  ${tw`
        flex-wrap
    `}
  @media(min-width: 640px) {
    ${tw`
        flex-row-reverse
        items-center
        `}
  }
`

const MemberImage = styled.img<{ dim: number }>`
  ${({ dim }) => `
        width: ${dim}px;
        height: ${dim}px;
    `}
  ${tw`
        rounded-full
        object-cover
        my-5
    `}
`

export const SocialLink = styled("a")<{ negative?: boolean }>`
  ${({ negative, ...props }) =>
    negative == null
      ? `background-image: ${`linear-gradient(to bottom left, ${themeGet(
          `colors.primary21`
        )(props)}, ${themeGet(`colors.primary22`)(props)})`};`
      : `background-color:${hexToRGBA("#ffffff", 0.9)}; 
      :hover {
        background-color:${hexToRGBA("#ffffff", 1)};
      }`}
  ${tw`
        w-10
        h-10
        mr-8
        rounded-full
        flex
        justify-center
        items-center
    `}
  :hover {
    ${tw`shadow-lg`}
  }
`
