import React from 'react';
import styled from "@emotion/styled"

import useWindowSize, { getSize } from '../../hooks/useWindowSize';

type ContainerProps = {
    children: any,
    width?: 'default' | 'large'
}
export const Container = ({ children, width = 'default' }: ContainerProps) => {
    const {type} = useWindowSize()
    const ratio = (width === 'default')
        ? getSize(type,['1fr', '1fr 4fr 1fr', '1fr 1fr 1fr'])
        : getSize(type,['1fr', '1fr 4fr 1fr', '1fr 4fr 1fr'])
    const position = getSize(type,[1, 2, 2])
    return (
        <Wrapper ratio={ratio}>
            <Content position={position}>{children}</Content>
        </Wrapper>
    )
}


const Wrapper = styled.div<{ratio:string}>`
    display: grid;
    grid-template-columns: ${({ratio}) => ratio};
    grid-gap: 0;
    justify-items: center;
    width: 100%;
`

const Content = styled.div<{position:number}>`
    grid-column: ${({position}) => position};
    width: 100%;
`

