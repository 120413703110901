import React from 'react';
import styled from "@emotion/styled"
import tw from "tailwind.macro";

type ContentLayerProps = {
    children: any,
}
export const ContentLayer = ({ children }: ContentLayerProps) => <ContentContainer>
    {children}
</ContentContainer>

export const SimpleContentLayer = ({children} : ContentLayerProps) => <SimpleContentContainer>
    {children}
</SimpleContentContainer>

const SimpleContentContainer = styled.div`
    ${tw`
    text-left
    p-8
    `}
`

const ContentContainer = styled.div`
    ${tw`
    text-left
    flex
    flex-col
    justify-end
    h-full
    p-8
    `}
`

