import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { Row, Text } from '../base'
import styled from '@emotion/styled'
import DropdownMenu from '../Dropdown/index';
import { SvgChevronDownBold } from '../../assets/svg';

export const isInLocales = (locales, locale) => locales.includes(locale)


const LanguageSwitcher = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages: allLanguages, language: currentLocale }) => {
          const languages = allLanguages.filter(lang => lang !== currentLocale)
          return <Row className="justify-between align-end mt-12">{languages.map((language, index) => (
            <div key={i}>
              {index === 0 && <>
                <a className="text-white">{currentLocale}</a>
                <a className="text-white">-</a></>}
              <LightTextLang
                key={language}
                onClick={() => { if (isInLocales(languages, language)) changeLocale(language); }}
              >{`${language}`}
              </LightTextLang>
              <LightText>{`${index !== languages.length - 1 ? ' | ' : ''}`}</LightText>
            </div>
          ))}</Row>
        }
        }
      </IntlContextConsumer>
    </div>
  )
}


export const DropDownLang = () => {
  return <IntlContextConsumer>
    {({ language, languages }) => {
      const content = <Row className="mr-8">
        <SvgChevronDownBold className='w-4 h-4 mt-2 pb-1 mr-2' />
        <div className="text-white text-lg">{`${language}`}</div>
      </Row>
      return <DropdownMenu
        up={false}
        dropdownItems={languages}
        content={content}
        itemClicked={item => {
          if (isInLocales(languages, item)) changeLocale(item);
          // if (callback) callback();
        }}
      />
    }}</IntlContextConsumer>
}

const LightText = styled.a`
opacity: 0.7
margin-left: 10px;
color: white;
`

const LightTextLang = styled(LightText)`
text-decoration: underline;
cursor: pointer;
`
export default LanguageSwitcher