const SvgAnimationLayerVoice = require("./muse_voice.svg")
const SvgAnimationLayerVoices = require("./muse_voices.svg")

const SvgSearchIcon = require("./search-icon.svg")

const SvgAbout1 = require("./about1.svg")
const SvgAbout2 = require("./about2.svg")
const SvgAbout3 = require("./about3.svg")

const SvgArrow = require("./arrow.svg")
const SvgClose = require("./close.svg")
const SvgChevronDown = require("./down_chevron.svg")
const SvgChevronDownBold = require("./down_chevron_bold.svg")

const SvgWhiteLogo = require("./muselogoWhite.svg")

const SvgFacebookIcon = require("./FacebookIcon.svg")
const SvgInstagramIcon = require("./InstagramIcon.svg")
const SvgInstagramNegativeIcon = require("./InstagramNegativeIcon.svg")

const SvgLinkedinIcon = require("./LinkedinIcon.svg")
const SvgLinkedinNegativeIcon = require("./LinkedinNegativeIcon.svg")

const SvgTwitterIcon = require("./TwitterIcon.svg")
const SvgWebsiteIcon = require("./WebsiteIcon.svg")

const SvgBrainIconCircle = require("./BrainIconCircled.svg")
const SvgBrainIconCircleActive = require("./BrainIconCircled-active.svg")
const SvgCameraIconCircle = require("./CameraIconCircled.svg")
const SvgCameraIconCircleActive = require("./CameraIconCircled-active.svg")
const SvgCirclesIconCircle = require("./CirclesIconCircled.svg")
const SvgCirclesIconCircleActive = require("./CirclesIconCircled-active.svg")
const SvgHeartIconCircle = require("./HeartIconCircled.svg")
const SvgHeartIconCircleActive = require("./HeartIconCircled-active.svg")
const SvgHistogramIconCircle = require("./HistogramIconCircled.svg")
const SvgHistogramIconCircleActive = require("./HistogramIconCircled-active.svg")
const SvgMicIconCircle = require("./MicIconCircled.svg")
const SvgMicIconCircleActive = require("./MicIconCircled-active.svg")
const SvgPenIconCircle = require("./PenIconCircled.svg")
const SvgPenIconCircleActive = require("./PenIconCircled-active.svg")
const SvgPlusIconCircle = require("./PlusIconCircled.svg")
const SvgPlusIconCircleActive = require("./PlusIconCircled-active.svg")

export {
  SvgAnimationLayerVoice,
  SvgAnimationLayerVoices,
  SvgSearchIcon,
  SvgAbout1,
  SvgAbout2,
  SvgAbout3,
  SvgArrow,
  SvgClose,
  SvgChevronDown,
  SvgChevronDownBold,
  SvgWhiteLogo,
  SvgFacebookIcon,
  SvgInstagramIcon,
  SvgInstagramNegativeIcon,
  SvgLinkedinIcon,
  SvgLinkedinNegativeIcon,
  SvgTwitterIcon,
  SvgWebsiteIcon,
  SvgBrainIconCircle,
  SvgBrainIconCircleActive,
  SvgCameraIconCircle,
  SvgCameraIconCircleActive,
  SvgCirclesIconCircle,
  SvgCirclesIconCircleActive,
  SvgHeartIconCircle,
  SvgHeartIconCircleActive,
  SvgHistogramIconCircle,
  SvgHistogramIconCircleActive,
  SvgMicIconCircle,
  SvgMicIconCircleActive,
  SvgPenIconCircle,
  SvgPenIconCircleActive,
  SvgPlusIconCircle,
  SvgPlusIconCircleActive,
}
