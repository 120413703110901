export const isBrowser = () => typeof window === "object"

export const flatContentfulNested = data => {
  if (!data) return {}
  const flatData = { ...data }
  Object.entries(data).forEach(([storyKey, storyVal]: [string, any]) => {
    storyVal?.hasOwnProperty(storyKey)
      ? (flatData[storyKey] = storyVal[storyKey])
      : ""
  })
  return flatData
}

export const interpolate = ({ start, end, progress }) =>
  start + progress * (end - start)

export const hexToRGBA = (hex, alpha) => {
  // const hex = colorStringtoHexCode(hexCodeStr);
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")"
  }
}
