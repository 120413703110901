import React from 'react';
import { useTheme } from 'emotion-theming';

import { TailwindConfig } from '../../config/theme/tailwindType'

type Color = "gray" | "white"
type ColorProps = {
    color?: Color,
}

export const IconMenu = ({color='white'}:ColorProps) => {
    const theme: TailwindConfig = useTheme();
    const themeColor = theme.colors;
    const fill = themeColor[color];

    return <svg id="Menu_Icon_-_on_white" data-name="Menu Icon - on white" xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8">
    <rect id="Rectangle_1470" data-name="Rectangle 1470" width="16" height="2" fill={fill}/>
    <rect id="Rectangle_1471" data-name="Rectangle 1471" width="16" height="2" transform="translate(0 6)" fill={fill}/>
  </svg>

}