import React from 'react';
import styled from "@emotion/styled";
import tw from "tailwind.macro";

import { Menu } from '../menu/menu';
import { IconMenu } from '../svg';
import { BG_TYPES, getHeaderStyle } from '../svg/Backgrounds';

import useWindowSize from '../../hooks/useWindowSize';

import { SvgClose } from '../../assets/svg';
import { Row, Text } from '../base';


type BottomMenuProps = {
    bgType?: BG_TYPES,
    toggleMenu:Function,
    menuOpen:boolean
}
export const BottomMenu = ({bgType, toggleMenu, menuOpen}:BottomMenuProps) => {
    const {type} = useWindowSize()

    return <>
        {<Menu open={menuOpen} toggleMenu={toggleMenu}/>}
        <BottomMenuContainer {...{bgType}} menuOpen={menuOpen}>
            <MenuLayout onClick={() => toggleMenu()} className='cursor-pointer'>
                <div>{menuOpen ? <SvgClose /> : <IconMenu color='black'/>}</div>
            </MenuLayout>
        </BottomMenuContainer>
    </>
}


const BottomMenuContainer = styled.div<{bgType?:BG_TYPES, menuOpen:boolean}>
`
    z-index: 15;
    ${({menuOpen}) => menuOpen && `background-color: transparent`};
    ${({menuOpen}) => !menuOpen && tw`bg-salmon w-full`}
    ${({menuOpen}) => menuOpen && tw`w-1/2`}
    ${tw`
        fixed
        bottom-0
        p-8
    `}
`

const MenuLayout = styled(Row)`
    ${tw`
    
    `}
`