import React from 'react'
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import { Link } from 'gatsby-plugin-intl';

import { Text } from '../base';
import { BG_TYPES, getHeaderStyle } from '../svg/Backgrounds';
import { IconMenu } from '../svg/Menu'
import { IMG_LOGO } from '../../assets/images/images';
import useWindowSize, { isMobile, getSize, isDesktop } from '../../hooks/useWindowSize';
import { SimpleContentLayer } from '../contentLayer/contentLayer';
import { Container } from '../container/container';
import { Menu } from '../menu/menu';
import { SecondaryButton } from '../buttons/buttons';
import { SvgClose } from '../../assets/svg'
import { DropDownLang } from '../languageSwitcher';

type HeaderProps = {
    bgType?: BG_TYPES,
    pageName?:string,
    toggleMenu:Function,
    menuOpen:boolean
}
export const Header = ({bgType, pageName, toggleMenu, menuOpen}:HeaderProps) => {
    const {type} = useWindowSize()
    const color = 'white'
    return (
        <>
            {!isMobile(type) && <Menu open={menuOpen} toggleMenu={toggleMenu}/>}
            <HeaderContainer {...{bgType}} menuOpen={menuOpen} className='shadow-lg'>
                <Link to='/'>
                    <ContentContainer width={getSize(type, ['100%', 'auto', 'auto'])}>
                        <LogoContainer>
                            <img src={IMG_LOGO} className='mr-8 w-20' />
                            <Text size='2xl' weight='bold' color={color}>muse</Text>
                        </LogoContainer>
                        <Text size='2xl' weight='light' color={color}>the voice of the visitor</Text>
                    </ContentContainer>
                </Link>
                {!isMobile(type) &&
                    <div className=' flex items-center'>
                        {isDesktop(type) && <DropDownLang/>}
                        <a href='https://app.muse.stream' target='_blank'><LoginButton>log in</LoginButton></a>
                        <div className='mx-2'/>
                        <div onClick={() => toggleMenu()} className='cursor-pointer ml-6 w-5'>
                            {menuOpen ? <SvgClose /> : <IconMenu />}
                        </div>
                    </div>
                    }
            </HeaderContainer>
            {pageName && <PageName>
                <SimpleContentLayer>
                    <Container width='large'>
                        <Text size='2xl' color='primary21'>{pageName}</Text>
                    </Container>
                </SimpleContentLayer>
            </PageName>}
        </>
    )
}

const LoginButton = styled.div`
    padding: 0.2em 0;
    ${tw`
        px-10
        text-md
        font-bold
        rounded-full
        cursor-pointer
        text-primary21
        bg-white
    `}
    :hover {
        ${tw`shadow-lg`}
    }
    :focus {
        ${tw`shadow-lg`}
    }
    :active {
        ${tw`shadow-lg`}
    }
`;

const ContentContainer = styled.div<{width:string}>`
    width: ${({width}) => width };
    ${tw`
        flex
        justify-between
        items-center
    `}
`

const LogoContainer = styled.div`
    ${tw`
        flex
        items-center
        mr-16
    `}
`

const LogoImage = styled.img`
    ${tw`
        mr-4
    `}
`

const HeaderContainer = styled.div<{bgType?:BG_TYPES, menuOpen:boolean}>
`
    z-index: 15;
    top:0;
    ${({bgType, theme}) => getHeaderStyle(bgType, theme)}
    ${({menuOpen}) => menuOpen && `background-image: none`};
    ${tw`
        top-0
        flex
        justify-between
        items-center
        w-full
        text-gray
        px-8
        py-3
    `}
`

const PageName = styled.div`
    ${tw`
        bg-salmon
        text-primary21
    `}
`