import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { useIntl } from "react-intl"
import { useStaticQuery, graphql } from "gatsby"
import tw from "tailwind.macro"
import { Link } from "gatsby-plugin-intl"

import { Text, SizeText } from "../base"
import { BackgroundCircle } from "../svg"
import { SecondaryButton } from "../buttons/buttons"

import useWindowSize, {
  getSize,
  isDesktop,
  isMobile,
} from "../../hooks/useWindowSize"

import { Title } from "../content/title"
import { SimpleParagraph } from "../content/paragraph"

import ROUTES from "../../config/constants/route"
import { DropDownLang } from "../languageSwitcher"
import {
  FullScreenContent,
  ContentRigth,
  ContentLeft,
} from "../container/fullScreenContent"

type MenuProps = {
  open: boolean
  toggleMenu: Function
}

export const Menu = ({ open, toggleMenu }: MenuProps) => {
  const { locale } = useIntl()
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allStrapiMenu {
        nodes {
          title
          description
          homeLinkText
          servicesLinkText
          storiesLinkText
          musesLinkText
          partnersLinkText
          contactLinkText
          locale
        }
      }
    }
  `)
  const langData = data.allStrapiMenu.nodes.filter(
    node => node.locale === locale
  )
  const {
    title,
    description,
    homeLinkText,
    servicesLinkText,
    storiesLinkText,
    musesLinkText,
    partnersLinkText,
    contactLinkText,
    //   socialLinks
  } = langData?.[0] ?? {}

  const MENUS = [
    { text: homeLinkText, link: ROUTES.HOME },
    { text: servicesLinkText, link: ROUTES.SERVICES },
    { text: storiesLinkText, link: ROUTES.STORIES },
    { text: musesLinkText, link: ROUTES.MUSES },
    { text: partnersLinkText, link: ROUTES.PARTNERS },
    { text: contactLinkText, link: ROUTES.CONTACT },
  ]

  const { type } = useWindowSize()
  const layout = getSize(type, ["1fr", "1fr", "1fr 1fr 2fr 1fr 1fr"])
  const policeSize: SizeText = getSize(type, ["3xl", "5xl", "6xl"])
  const positionLink = getSize(type, [1, 1, 4])
  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0)
      document.body.style.overflow = "hidden"
      document.body.style["-webkit-overflow-scrolling"] = "touch"
    }
    return () => {
      document.body.style.overflow = "unset"
      document.body.style["-webkit-overflow-scrolling"] = "unset"
    }
  })

  return (
    <>
      {open && (
        <MenuContainer>
          <BackgroundCircle />
          <FullScreenContent layout={layout}>
            {isDesktop(type) && (
              <ContentLeft>
                <Title color="white" bold>
                  {title}
                </Title>
                <SimpleParagraph color="white">
                  <Text size="lg" weight="light" color="white">
                    {description}
                  </Text>
                </SimpleParagraph>
              </ContentLeft>
            )}
            <ContentRigth position={positionLink}>
              {MENUS.map((menu, index) => (
                <Text size={policeSize} weight="hairline" color="white">
                  <Link
                    key={index}
                    to={menu.link}
                    activeStyle={{ fontWeight: "900" }}
                    onClick={() => toggleMenu()}
                  >
                    {menu.text}
                  </Link>
                </Text>
              ))}
              {!isDesktop(type) && (
                <>
                  <div className="py-3" />
                  <DropDownLang />
                  {isMobile(type) && <div className="py-3" />}
                </>
              )}
              {isMobile(type) && (
                <>
                  <div className="my-5" />
                  <SecondaryButton link="/" text="log in" />
                </>
              )}
            </ContentRigth>
          </FullScreenContent>

          {!isMobile(type) && (
            <div className="absolute bottom-0 w-full p-8">
              <div className="flex justify-between">
                <div className="text-white text-xs">{`© muse ${new Date().getFullYear()}`}</div>
                {/* <div className='text-white z-50'>
                    {[{title: 'eM+', link: 'https://google.com'}].map(({ title, link }) => (
                        <a href={link} target='_blank' className='pl-8 underline text-xs '>{title}</a>
                    ))}
                </div> */}
              </div>
            </div>
          )}
        </MenuContainer>
      )}
    </>
  )
}

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 10;
  ${tw`
        w-full
        min-h-screen
        h-screen
        bg-primary22
    `}
`
